<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="475 640 325 500"
    style="enable-background:new 0 0 1190.6 1683.8;"
    xml:space="preserve"
  >
    <path
      :class="parte ==='gemelo' || parte ==='otro' ? 'part_marked':''"
      d="M636.6,1080.3c20.9-5.9,32.5,2.1,32.5,2.1l0.1,0.1c0.3-3,0.6-6,0.9-9c0.2-1.9-0.8-3.9-0.7-5.8
		c0.6-10,0.8-20.1,2.1-30.1c1-7.9,2-16.3,5.4-23.2c8.9-18,12.6-36.8,12.4-56.6c0-1.5,0.2-3,0.3-4.4c0.2-8.1,1.4-16.4,0.4-24.3
		c-0.5-3.7-0.3-6.9,0.5-10.1l0-0.1c0,0-28.1,15.8-44.8-6.3l0,0.1c-1.4,7.6-3.1,15.1-4.9,22.7c-2.1,8.9-3,18.2-3.2,27.3
		c-0.2,8.2,1.5,16.4,1.7,24.6c0.6,17.8,0.7,35.5,1.1,53.3c0,0.8-0.1,1.7,0,2.5c2,9.4,0.8,18.5-1.9,27.7
		C637.6,1073.8,636.9,1076.9,636.6,1080.3L636.6,1080.3z"
    />
    <path
      :class="parte ==='muslo' || parte ==='otro' ? 'part_marked':''"
      d="M645.7,912.5c16.7,22,44.8,6.3,44.8,6.3l0,0c0.7-2.7,1.7-5.2,3.1-7.9c3.2-6,6-12.4,7.5-19
		c3.5-15.2,5.6-30.8,9.3-45.9c3.6-14.5,8.3-28.7,12.8-43c2.4-7.7,5.6-15.2,8.3-22.8c1.4-3.9,2.4-8,3.8-13.1l-0.4-0.7l-10-15.3
		c-11.6-17.8-26.5-33.1-43.9-45.2l-24.3-16.8l-0.6-0.4c-0.3,1.5-0.6,3-0.8,4.5c-2.1,13.4-3.4,27-5.2,40.4
		c-2.2,16.8-5.5,33.6-6.7,50.5c-1.1,15.9-1.3,32.1,2,47.9c3.5,17,5.6,33.8,4.2,51.4C648.9,893.3,647.5,902.9,645.7,912.5
		L645.7,912.5z"
    />
    <path
      :class="parte ==='tobillo' || parte ==='otro' ? 'part_marked':''"
      d="M636.6,1080.4c-0.1,1.1-0.1,2.2-0.1,3.3c0.2,7.6-3.6,13.8-7.4,19.4c-4.7,6.8-11.3,12.4-17.5,18
		c-6.2,5.7-5.5,12.2,2.1,15.5c3.2,1.4,6.4,2.8,9.6,4.3c7.3,0,14.7,0,22,0c2.2-2.1,4.6-3.9,6.5-6.2c1.8-2.1,3.7-4.4,4.4-6.9
		c1.2-4.7,0.4-10.1,7.5-10.9c1.3-0.1,3.1-2.9,3.4-4.7c0.5-2.7-0.4-5.6-0.3-8.4c0.2-4.2,0.8-8.3,1.3-12.4c0.3-3,0.7-5.8,1-8.8l0-0.2
		c0,0-11.7-8.1-32.6-2.1L636.6,1080.4z"
    />
  </svg>
</template>

<script>
export default {
  name: "PiernaDerecha",
  props: ["parte"],
};
</script>
<style lang="sass">
path
	fill: var(--v-gris1-base) !important
	&.part_marked
		fill: var(--v-gris2-base) !important
		stroke: #272727
		stroke-width: 0.5
		stroke-miterlimit: 10
.selected_inner
	path
		fill: var(--v-gris1-base) !important
		&.part_marked
			fill: var(--v-primary-base) !important
			fill: #E4BB6D
			stroke: #000000
			stroke-width: 0.5
			stroke-miterlimit: 10

#body_selector .v-card:hover
	path.part_marked
		fill: #E4BB6D !important
</style>